@import "custom.scss";

.mobileMenuOpen {
    overflow: hidden;
}

.mobileMenuOpen {
  .Navigation  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: #fff;
  }
}

.mobileMenuOpen .TopBar{
    position: relative;
    z-index: 99999;
}