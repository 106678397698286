.Footer a{color: #fff}
.Footer a:hover{
    text-decoration: none;
    color: #fff;
}

.Footer h2{
    margin-bottom: 20px;
}
 
.Footer ul li {
    margin-bottom:7px;
}